import "./App.css";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/header.js";
import Menuoverlay from "./components/menuoverlay.js";
import Footer from "./components/Footer.js";
import Alltemplates from "./components/pages/alltemplates";
import TemplatesPreview from "./components/pages/templatesPreview";
import ReactGA from "react-ga";
import Download from "./components/pages/download";
import NotFound from "./components/pages/404";
import Team from "./components/pages/team";
import Playground from "./components/tweetTemplates/playground";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-192091890-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <div className="grid content-start place-content-center">
        <Header />
        <Router>
          <Switch>
            <Route path="/" exact component={Alltemplates} />
            <Redirect path="/templates" exact to={Alltemplates} />
            <Redirect
              from="/templates/transflex"
              to="/templates/transparentflex"
            />
            <Route path="/templates/:id" exact component={TemplatesPreview} />
            <Route path="/download" exact component={Download} />
            <Route path="/playground" exact component={Playground} />
            <Route path="/team" exact component={Team} />
            {/* <Route path="/play" exact>
              <Loader visible={true} />
            </Route> */}
            <Route path="/404">
              <NotFound></NotFound>
            </Route>
            <Redirect from="*" to="/404" />
          </Switch>
        </Router>
        <Footer />
        <Menuoverlay />
      </div>
    </>
  );
}

export default App;
