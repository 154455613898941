import React, { useCallback } from "react";
import useFitText from "use-fit-text";
import "../index.css";
import Twemoji from "react-twemoji";
import reactStringReplace from "react-string-replace";
import classNames from "classnames";

const CustomText = ({ text, funks, isImage, mode, bgtype }) => {
  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
  const onFinish = useCallback((fontSize) => {
    console.log("finished", fontSize);

    wait(200);

    funks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fontSize, ref } = useFitText({
    maxFontSize: 275,
    minFontSize: 100,
    onFinish: onFinish,
  });

  let replacedText;
  replacedText = reactStringReplace(text, /@(\w+)/g, (match, i) => (
    <span
      className={classNames("spantext", {
        textHashtag: bgtype !== "borderflex",
      })}
      key={match + i}
      href={`https://twitter.com/${match}`}
    >
      @{match}
    </span>
  ));
  replacedText = reactStringReplace(
    replacedText,
    /https?:\/\/.*[\r\n]*/g,
    (match, i) => ""
  );

  // Match hashtags
  replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
    <span
      className={classNames("spantext", {
        textHashtag: bgtype !== "borderflex",
      })}
      key={match + i}
      href={`https://twitter.com/hashtag/${match}`}
    >
      #{match}
    </span>
  ));

  return (
    <div
      ref={ref}
      className="tweettextbox tweettext"
      id="textbox"
      style={{ fontSize, width: 760, height: isImage ? 266 : 738 }}
    >
      <Twemoji
        options={{ className: "twemoji" }}
        id="textonly"
        style={{
          color:
            bgtype !== "borderflex"
              ? mode === "dark"
                ? "#FFFFFF"
                : "#000000"
              : mode === "light"
              ? "#FFFFFF"
              : "#000000",
        }}
      >
        {replacedText}
      </Twemoji>
    </div>
  );
};
export default CustomText;
