import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const TweetSearch = ({ searchText, setSearchText }) => {
  const searchTextHandler = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setSearchText(e.target.value);
  };
  //   const history = useHistory();
  //   const [searchText, setSearchText] = useState("");

  return (
    <div className="py-4 px-8 gradientBorder border rounded-full">
      <input
        className="bg-transparent w-full focus:outline-none mobCTA-SMALL md:deskCTA-SMALL transform-none"
        type="text"
        name="tweeturl/id"
        value={searchText}
        onChange={searchTextHandler}
        placeholder="eg: https://twitter.com/FlexeetClub/status/137..."
        autoCapitalize="none"
      />
    </div>
  );
};

export default TweetSearch;
