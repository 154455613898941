import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import "../App.css";
const CustomLoader = ({ visible }) => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("./anims.json"),
    });
  }, []);

  return (
    <div
      className={`bgGradient ${
        visible ? `grid` : `hidden`
      } content-center justify-center`}
    >
      <div ref={container} className="m-0"></div>
    </div>
  );
};

export default CustomLoader;
