exports.validRoutes = [
  "imageflex",
  "gradientflex",
  "colorflex",
  "transparentflex",
  "borderflex",
];
exports.URLS = {
  colorflex: "https://i.ibb.co/cXQDkY5/Color-Flex.png",
  borderflex: "https://i.ibb.co/52hQkSf/borderflex.png",
  transparentflex: "https://i.ibb.co/sbz8nqg/Transparent-Flex.png",
  imageflex: "https://i.ibb.co/BBBvgDz/Image-Flex.png",
  gradientflex: "https://i.ibb.co/Qr6qVZr/Gradient-Flex.png",
};

exports.templates = [
  {
    name: "imageflex",
    url: "templates/imageflex",
  },
  {
    name: "gradientflex",
    url: "templates/gradientflex",
  },
  {
    name: "colorflex",
    url: "templates/colorflex",
  },
  {
    name: "borderflex",
    url: "templates/borderflex",
  },
];
