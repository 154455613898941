import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import BaseTweet from "../tweetTemplates/BaseTweet";
import CustomText from "../customText";
import CustomLoader from "../customLoader";
import { templates, URLS } from "../utils/templatesUtils";
import Card from "../Card";

const Download = ({ match }) => {
  console.log(match);

  const { state } = useLocation();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [canvasIMG, setCanvas] = useState();
  const [imgH, setIMGH] = useState(0);
  const [imgW, setIMGW] = useState(0);

  useEffect(() => {
    setLoading(true);
    // console.log("hello");
    // console.log();
    if (state === undefined) {
      history.replace("/");
      return;
    }

    console.log(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sleep = async (ms) => {
    return await new Promise((resolve) => setTimeout(resolve, ms));
  };

  const convertToImg = () => {
    window.scrollTo(0, 0);
    var CustomTextElement = document.getElementById("textonly");
    html2canvas(CustomTextElement, {
      backgroundColor: null,
      scrollX: 0,
      scrollY: 0,
      useCORS: true,
      scale: 1,

      onclone: async (doc) => {
        await sleep(2000);
      },
    }).then((can) => {
      setCanvas(can);
      setIMGH(can.height);
      setIMGW(can.width);
      setLoading(false);
    });
  };

  return isLoading ? (
    state === undefined ? (
      <></>
    ) : (
      <div className="max-w-screen-2xl w-screen overflow-x-hidden">
        <CustomLoader visible={isLoading} />
        <div className="bg-green-200">
          <div className="mx-32">
            {/* <Loading loading={false} background="#282C34" loaderColor="#FFFFFF" /> */}
            <CustomText
              text={state["data"]["tweet_text"] ?? ""}
              funks={() => convertToImg()}
              isImage={state["data"]["containsMedia"]}
              mode={state["colormode"]}
              bgtype={state["bgtype"]}
            />
          </div>
        </div>
      </div>
    )
  ) : (
    <>
      <BaseTweet
        canvas={canvasIMG}
        imgH={imgH}
        imgW={imgW}
        data={state["data"]}
        bgtype={state["bgtype"]}
        mode={state["colormode"]}
        size={state["size"]}
        isImage={state["data"]["containsMedia"]}
      ></BaseTweet>

      <div className="bgCyan25">
        <div className="textBlack mobH4 lg:deskH4 mx-4 md:mx-12 lg:mx-32  mt-12 md:mt-16 lg:mt-20 gap-y-8  text-center lg:text-left ">
          More Templates
        </div>
        <div className="max-w-screen-2xl grid grid-cols-4 md:grid-cols-8 gap-6 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 items-center mt-8 md:mt-12 mb-28 md:mb-28 lg:mb-40">
          {templates.map((data) => (
            <Card name={data.name} url={data.url} imgURL={URLS[data.name]} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Download;
