import React from "react";
// import "../styles/global.css"

const IconLink = ({ title, url, iconSVG, type }) => {
  const TYPES = {
    footer: "textCyan mobCTA-SMALL md:deskCTA-SMALL",
    header: "textGradient deskCTA-SMALL",
    overlay: "textCyan mobH4",
  };

  return (
    <a href={url} target="_blank">
      <div
        className={`flex flex-row justify-items-center items-center gap-2 m-auto ${TYPES[type]}`}
      >
        <img src={iconSVG} alt={title} className="m-auto navIcon" />
        {title}
      </div>
    </a>
  );
};

export default IconLink;
