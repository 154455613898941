import React from 'react'

const radioButton = ({ label, value, checked, setter, img }) => {
    return (
        <>
        <input type="radio" className="hidden" checked={checked === value} />
        <div
          className="flex flex-row rounded-lg out justify-center py-3 px-4 flex-grow gap-x-4"
          onClick={() => setter(value)}
        >
          <img src={img} alt="link" width="56" className="m-0" />
          <div
            className={`mobCTA-SMALL md:deskCTA-SMALL self-center ${
              checked === value ? `textGradient` : `textBlack`
            }`}
          >
            {label}
          </div>
        </div>
      </>
    );
    
}

export default radioButton
