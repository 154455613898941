import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { validRoutes, URLS } from "../utils/templatesUtils";
import LinkIconGD from "../../images/Icons/Link_gd.svg";
import LightMode from "../../images/lightmode.png";
import DarkMode from "../../images/darkmode.png";
import PostSize from "../../images/postsize.png";
import StorySize from "../../images/storysize.png";
import TwitterBlack from "../../images/Icons/TwitterBlack.svg";
import ReactGA from "react-ga";
import TweetSearch from "../TweetSearch";
import RadioInput from "../radioButton";
import Button from "../Button";

const TemplatesPreview = ({ match }) => {
  // useRef();
  const history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searching, setSearching] = useState(false);
  const [colorMode, setColorMode] = useState("light");
  const [size, setSize] = useState("post");

  function copyLink() {
    var dummy = document.createElement("input"),
      text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    
  }

  useEffect(() => {
    if (!validRoutes.includes(match.params.id)) {
      history.replace("/");
    }
  });

  const base = "https://flexeet-twimage-api.cyclic.app/v2/gettweet?id=";

  async function fetchTweet(id) {
    const response = await fetch(base + id);
    return response;
  }

  async function HandleAPIRes(response) {
    if (response.status === 200) {
      const jsonData = await response.json();
      console.log(jsonData);

      if (jsonData["containsMedia"]) {
        if (jsonData["media"][0]["type"] === "photo") {
          ReactGA.event({
            category: "TweetSearch",
            action: "Tweet with media photo fetched",
          });
          ReactGA.event({
            category: "TweetDetails",
            action: `bgtype :  ${match.params.id} , color mode: ${colorMode} , post size: ${size}`,
          });

          setSearching(false);
          history.push({
            pathname: "/download",
            state: {
              type: "nondirect",
              data: jsonData,
              bgtype: match.params.id,
              colormode: colorMode,
              size: size,
            },
          });
        } else {
          ReactGA.event({
            category: "TweetSearch",
            action: "Tweet with non Support media fetched",
          });
          alert("we cant convert this");
          setSearching(false);
          return;
        }
      }

      ReactGA.event({
        category: "TweetSearch",
        action: "Tweet without media fetched",
      });
      ReactGA.event({
        category: "TweetDetails",
        action: `bgtype :  ${match.params.id} , color mode: ${colorMode} , post size: ${size}`,
      });

      setSearching(false);
      history.push({
        pathname: "/download",
        state: {
          type: "nondirect",
          data: jsonData,
          bgtype: match.params.id,
          colormode: colorMode,
          size: size,
        },
      });
    } else {
      const jsonData = await response.json();
      console.log(jsonData);
      ReactGA.event({
        category: "TweetSearch",
        action: "Tweet fetch error : " + jsonData["errors"][0]["message"],
      });
      alert(jsonData["errors"][0]["message"]);
      setSearching(false);
    }
  }

  const SubmitTweetHandler = async (e) => {
    e.preventDefault();
    setSearching(true);
    if (
      !isNaN(searchText) &&
      searchText.length > 14 &&
      searchText.length < 21
    ) {
      fetchTweet(searchText).then((data) => HandleAPIRes(data));
      setSearchText("");
    } else if (searchText.includes("status/")) {
      let id = searchText
        .toString()
        .split("status/")[1]
        .split("/")[0]
        .split("?")[0];
      if (!isNaN(id) && id.length > 14 && id.length < 21) {
        console.log(id);
        setSearchText("");
        fetchTweet(id).then((data) => HandleAPIRes(data));
        return;
      }
      ReactGA.event({
        category: "TweetSearch",
        action: "Invalid Tweet URL or ID pasted",
      });

      setSearching(false);

      alert("Enter a Valid Tweet URL");
    } else {
      ReactGA.event({
        category: "TweetSearch",
        action: "Invalid Tweet URL or ID pasted",
      });

      setSearching(false);

      alert("Enter a Valid Tweet ID or URL");
    }
  };

  return (
    <>
      <div className="max-w-screen-2xl grid grid-cols-4 gap-x-8 gap-y-8 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 my-4 md:my-12">
        <div className="col-span-4 lg:col-span-6">
          <img
            src={URLS[match.params.id]}
            alt="templates"
            className="m-0  rounded-md overflow-hidden"
          />
        </div>
        <div className="col-span-4 lg:col-start-8 lg:col-span-5 ">
          <div className="flex flex-row items-center justify-between">
            <div className="mobH4 md:deskH4 textGradient capitalize ">
              {match.params.id}
            </div>

            <img
              src={LinkIconGD}
              alt="link"
              className="m-0"
              onClick={copyLink}
            />
          </div>
          <div className="flex flex-row items-center justify-between mt-8">
            <div className="deskCTA-BIG md:deskCTA-BIG textBlack">
              Paste your Tweet link:
            </div>
            <a href="http://twitter.com/" target="_blank">
              <img src={TwitterBlack} alt="twitter link" className="m-0" />
            </a>
          </div>
          <div className="mt-4">
            <TweetSearch
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>

          <div className="deskCTA-BIG md:deskCTA-BIG textBlack mt-8">
            Choose Color Mode:
          </div>
          <div className="mt-4 flex flex-row gap-x-12">
            <RadioInput
              label="Light"
              value="light"
              checked={colorMode}
              setter={setColorMode}
              img={LightMode}
            />
            <RadioInput
              label="Dark"
              value="dark"
              checked={colorMode}
              setter={setColorMode}
              img={DarkMode}
            />
          </div>
          <div className="deskCTA-BIG md:deskCTA-BIG textBlack mt-8">
            Choose Image Size:
          </div>
          <div className="mt-4 flex flex-row gap-x-12">
            <RadioInput
              label="Post"
              value="post"
              checked={size}
              setter={setSize}
              img={PostSize}
            />
            <RadioInput
              label="Story"
              value="story"
              checked={size}
              setter={setSize}
              img={StorySize}
            />
          </div>
          <div className="mt-8">
            <Button
              title="Continue"
              buttonStyle="btnPrimary"
              buttonSize="btnBig"
              isDisabled={searching}
              fitType="btnFull"
              onBtnClick={SubmitTweetHandler}
              isLoading={searching}
            ></Button>
          </div>
        </div>
        <div className="col-span-full bgCyan25">
          {/* <div className="col-span-4">
            <img src={news} alt="newsletter" className="m-0 px-14" />
            <div className="mobH4 md:deskH4 textGradient text-center md:text-left">
              Get early access & exclusive benefits!
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TemplatesPreview;
