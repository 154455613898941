import React from "react";
import logo from "../images/FlexeetLogoCyan.svg";
import closeGD from "../images/Icons/Close_gd.svg";
import mailW from "../images/Icons/MailW.svg";
import twitterW from "../images/Icons/TwitterW.svg";
import igW from "../images/Icons/InstagramW.svg";
// import "../styles/global.css"
import IconLink from "../components/IconLink";
import Button from "./Button";

const menuoverlay = () => {
  return (
    <div
      className="overlaymenu lg:hidden flex flex-col justify-items-center"
      id="overlay"
    >
      <div className="sm:mx-4 md:mx-4 justify-center content-center">
        <div className="max-w-7xl self-center flex flex-row  justify-between items-center mx-4 my-5">
          <img src={logo} alt="flexeet logo cyan" className="my-auto" />
          <img
            src={closeGD}
            onClick={() => {
              console.log("close");
              document.getElementById("overlay").style.top = "-500%";
              document.getElementById("overlay").style.left = "-500%";
            }}
            alt="close"
            className="lg:hidden md:flex sm:flex flex-row mx-2 my-auto justify-items-center items-center h-8 w-8"
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-items-center">
        <div className="flex flex-col gap-8 items-center justify-items-center mb-20 mt-16">
          <IconLink
            title="Instagram"
            url="https://www.instagram.com/flexeetclub/"
            iconSVG={igW}
            type="overlay"
          />
          <IconLink
            title="Twitter"
            url="https://www.twitter.com/flexeetclub/"
            iconSVG={twitterW}
            type="overlay"
          />
          <IconLink
            title="Contact"
            url="mailto:hello@flexeet.club"
            iconSVG={mailW}
            type="overlay"
          />
        </div>
        <a href="/templates">
          <Button
            title="Start Creating"
            buttonStyle="btnWhite"
            buttonSize="btnBig"
            isDisabled={false}
          />
        </a>
      </div>
      <div className="flex-grow"></div>
      <div className="mobCTA-SMALL textCyan text-center self-center text-sm my-8">
        Made with ❤️ in India <br />© 2021 All rights reserved
      </div>
    </div>
  );
};

export default menuoverlay;
