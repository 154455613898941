export function getCrop(image, size, clipPosition = "center-middle") {
  const width = size.width;
  const height = size.height;
  const aspectRatio = width / height;

  let newWidth;
  let newHeight;

  const imageRatio = image.width / image.height;

  if (aspectRatio >= imageRatio) {
    newWidth = image.width;
    newHeight = image.width / aspectRatio;
  } else {
    newWidth = image.height * aspectRatio;
    newHeight = image.height;
  }

  let x = 0;
  let y = 0;
  if (clipPosition === "left-top") {
    x = 0;
    y = 0;
  } else if (clipPosition === "left-middle") {
    x = 0;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === "left-bottom") {
    x = 0;
    y = image.height - newHeight;
  } else if (clipPosition === "center-top") {
    x = (image.width - newWidth) / 2;
    y = 0;
  } else if (clipPosition === "center-middle") {
    x = (image.width - newWidth) / 2;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === "center-bottom") {
    x = (image.width - newWidth) / 2;
    y = image.height - newHeight;
  } else if (clipPosition === "right-top") {
    x = image.width - newWidth;
    y = 0;
  } else if (clipPosition === "right-middle") {
    x = image.width - newWidth;
    y = (image.height - newHeight) / 2;
  } else if (clipPosition === "right-bottom") {
    x = image.width - newWidth;
    y = image.height - newHeight;
  } else if (clipPosition === "scale") {
    x = 0;
    y = 0;
    newWidth = width;
    newHeight = height;
  } else {
    console.error(
      new Error("Unknown clip position property - " + clipPosition)
    );
  }

  return {
    cropX: x,
    cropY: y,
    cropWidth: newWidth,
    cropHeight: newHeight,
  };
}

export function clipSquare(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
}

export function getHashtagColor(mode, bgtype) {
  return bgtype === "borderflex"
    ? mode === "light"
      ? "#FFFFFF"
      : "#000000"
    : "#1d9bf0";
}
export function getTextColor(mode, bgtype) {
  return bgtype === "borderflex"
    ? mode === "light"
      ? "#FFFFFF"
      : "#000000"
    : mode === "light"
    ? "#000000"
    : "#FFFFFF";
}
