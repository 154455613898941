/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import twitterLogo from "../../images/Icons/Twitter_gd.svg";
const Team = () => {
  return (
    <>
      <div className="overflow-x-hidden">
        <div className="bodybg"></div>
        <div className="w-screen max-w-screen-2xl ">
          <div className="flex flex-col mx-4 md:mx-12 lg:mx-32 mt-8 overflow-hidden">
            <div className="mobH1 md:deskH1 textGradient text-center md:text-left">
              We Believe In Flexing! <br></br> Flex Craft, Flex Thoughts, Flex
              Life
            </div>

            <div className="mt-20 ">
              <div className="mobH3 md:deskH3 textBlack mt-8  text-center md:text-left">
                Meet The Team
              </div>

              <div className="mt-8 grid grid-cols-4 lg:grid-cols-8 mb-20 gap-y-8">
                <div className="flex flex-col col-span-4">
                  <div className=" flex flex-row items-center mb-8">
                    <img
                      src="https://rajvaya.netlify.app/static/c7e2206157a25722b1a077fc65944513/64b17/pp.jpg"
                      alt="profile"
                      className="m-0 w-1/6 rounded-full mr-4 border-2 border-indigo-800"
                    />
                    <div className="flex flex-col">
                      <div className="mobH4 md:deskH4 text-left textGradient">
                        Raj Vaya
                      </div>
                      <div className="mobPB md:deskPB text-left textBlack">
                        Developer
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <img src={twitterLogo} alt="profile" className="mr-2" />

                    <a
                      href="https://twitter.com/rajtweetts"
                      target="_blank"
                      className="mobCTA-SMALL md:deskCTA-SMALL textGradient"
                    >
                      @rajtweetts
                    </a>
                  </div>
                </div>{" "}
                <div className="flex flex-col col-span-4">
                  <div className=" flex flex-row items-center mb-8">
                    <img
                      src="https://i.ibb.co/LC0bgP7/akshayPP.png"
                      alt="twitter logo"
                      className="m-0 w-1/6 rounded-full mr-4 border-2 border-indigo-800"
                    />
                    <div className="flex flex-col">
                      <div className="mobH4 md:deskH4 text-left textGradient">
                        Akshay Agrawal
                      </div>
                      <div className="mobPB md:deskPB text-left textBlack">
                        Designer
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <img
                      src={twitterLogo}
                      alt="twitter logo"
                      className="mr-2"
                    />

                    <a
                      href="https://twitter.com/axayagrawal"
                      target="_blank"
                      className="mobCTA-SMALL md:deskCTA-SMALL textGradient"
                    >
                      @axayagrawal
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
