import classNames from "classnames";
import React from "react";
import { Rect } from "react-konva";
import useImage from "use-image";
import { getCrop, getTextColor } from "./utils/CanvasUtils";

const RoundedImage = ({
  x,
  y,
  width,
  height,
  imageurl,
  tweetMediaRef,
  mode,
  bgtype,
  cr,
  makeCanvas
}) => {
  const [media, mediastatus] = useImage(imageurl, "anonymous");
  // const tweetMediaRef = React.useRef(null);

  React.useEffect(() => {
    if (mediastatus === "loaded") {
      if (media.height * (width / media.width) >= 434) {
        tweetMediaRef.current.setAttrs({
          fillPatternRepeat: "no-repeat",
          fillPatternScale: {
            x: width / media.width,
            y: width / media.width,
          },
          fillPatternOffsetX: (media.width * (width / media.width)) / 2,
          fillPatternOffsetY: (media.height * (width / media.width)) / 2,
          fillPatternX: (media.width * (width / media.width)) / 2,
          fillPatternY: 434 / 2,
        });

      
      }

      else {
        tweetMediaRef.current.setAttrs({
          height: height,
          fillPatternRepeat: "no-repeat",
          fillPatternScale: {
            x: width / media.width,
            y: width / media.width,
          },
          fillPatternOffsetX: (media.width * (width / media.width)) / 2,
          fillPatternOffsetY: (media.height * (width / media.width)) / 2,
          fillPatternX: (media.width * (width / media.width)) / 2,
          fillPatternY: height / 2,
        });
      }
    }
  }, [media, mediastatus]);

  return (
    <Rect
      x={x}
      y={y}
      width={width}
      height={height}
      ref={tweetMediaRef}
      fillPatternImage={media}
      cornerRadius={16}
      stroke={getTextColor(mode, bgtype)}
      strokeWidth={bgtype === "borderflex" ? 6 : 0}
    />
  );
};

export default RoundedImage;
