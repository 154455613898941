import React, { useRef, useEffect, useState } from "react";
import { useWindowSize } from "@react-hook/window-size";
import {
  Stage,
  Layer,
  Group,
  Rect,
  Image as KImage,
  Text,
  Circle,
  Path,
} from "react-konva";
import useImage from "use-image";
import Button from "../Button";
import ReactGA from "react-ga";
import classNames from "classnames";
import { HexColorPicker, HexColorInput } from "react-colorful";
import RoundedImage from "../RoundedImage";
import { getHashtagColor, getTextColor } from "../utils/CanvasUtils";

const BaseTweet = ({ canvas, data, isImage, bgtype, mode, size }) => {
  const [isLoading, setisLoading] = useState(false);
  const [showWatermark, toggleWaterMark] = useState(true);
  const [colorN, setNColor] = useState("#aabbcc");
  const [width, height] = useWindowSize();
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);

  const [ProfilePicture, ProfiePictureStatus] = useImage(
    data["profile_image_url_https"],
    "anonymous"
  );
  var collection = bgtype === "gradientflex" ? "55998366" : "98908811";
  var bgurl =
    size === "post"
      ? `https://source.unsplash.com/collection/${collection}/1080x1080`
      : `https://source.unsplash.com/collection/${collection}/1080x1920`;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [bg, bgs] = useImage(bgurl, "anonymous");

  // console.log(data["media"][0]["sizes"]["large"]["h"]);

  //refs of nodes

  const canvasRef = useRef(null);
  const layerRef = useRef(null);
  const baseBGRef = useRef(null);
  const tweetGroupRef = useRef(null);
  const tweetGroupBGRef = useRef(null);
  const tweetTextCanvasRef = useRef(null);
  const tweetMediaRef = useRef(null);
  const headerGroupRef = useRef(null);
  const ProfilePictureRef = useRef(null);
  const userNameRef = useRef(null);
  const verifiedIconRef = useRef(null);

  // function getRandomColor() {
  //   var letters = "0123456789ABCDEF";
  //   var color = "#";
  //   for (var i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // }

  //variables

  var stageWidth = 1080;
  var stageHeight = size === "post" ? 1080 : 1920;
  var media, mediastatus;
  var nbg = new Image();
  let nbgurl = "";
  nbg.crossOrigin = "Anonymous";

  nbg.onload = function () {
    // console.log("NBG LOADED");
    baseBGRef.current.setAttrs({
      fillPatternImage: nbg,
    });
    canvasRef.current.draw();
    setisLoading(false);
  };

  //hooks
  const [wmimg, wmstatus] = useImage(
    "https://i.ibb.co/T0wBQ9c/Watermark.png",
    "anonymous"
  );

  function makeCanvas() {
    if (canvas.height > 0) {
      tweetTextCanvasRef.current.setAttrs({
        y: headerGroupRef.current.getClientRect()["height"],
      });
    }

    if (isImage) {
      tweetMediaRef.current.setAttrs({
        y:
          headerGroupRef.current.getClientRect()["height"] +
          (tweetTextCanvasRef.current.getClientRect()["height"] !== null
            ? tweetTextCanvasRef.current.getClientRect()["height"]
            : 0) +
          40,
      });
    }
    verifiedIconRef.current.setAttrs({
      x:
        userNameRef.current.getClientRect()["width"] +
        userNameRef.current.getAbsolutePosition()["x"] +
        8,
    });
    tweetGroupBGRef.current.setAttrs({
      height:
        (tweetTextCanvasRef.current.getClientRect()["height"] !== null
          ? tweetTextCanvasRef.current.getClientRect()["height"]
          : 0) +
        headerGroupRef.current.getClientRect()["height"] +
        40 +
        (isImage ? tweetMediaRef.current.getClientRect()["height"] + 40 : 0),
    });

    tweetGroupRef.current.setAttrs({
      offsetX: tweetGroupRef.current.getClientRect()["width"] / 2,
      offsetY: tweetGroupRef.current.getClientRect()["height"] / 2,
      x: stageWidth / 2,
      y: stageHeight / 2,
    });
    canvasRef.current.draw();
    reSize();
  }

  function Update() {
    makeCanvas();
    // reSize();
    // canvasRef.current.draw();
  }

  useEffect(() => {
    makeCanvas();
  }, []);

  const searchTextHandler = (e) => {
    e.preventDefault();
    // console.log(e.target.value);
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (ProfiePictureStatus === "loaded") {
      ProfilePictureRef.current.setAttrs({
        height: 80,
        fillPatternImage: ProfilePicture,
        fillPatternRepeat: "no-repeat",
        fillPatternOffset: { x: 40, y: 40 },
        fillPatternScale: {
          x: 80 / ProfilePicture.width,
          y: 80 / ProfilePicture.height,
        },
      });
      canvasRef.current.draw();
    }
  }, [ProfilePicture, ProfiePictureStatus]);

  // function wait(ms) {
  //   var start = new Date().getTime();
  //   var end = start;
  //   while (end < start + ms) {
  //     end = new Date().getTime();
  //   }
  // }

  useEffect(() => {
    reSize();
    // makeCanvas();
  }, [width, height]);

  function reSize() {
    var container = document.getElementById("canvasbox");
    var containerWidth = container.offsetWidth;
    var scale = containerWidth / stageWidth;
    // console.log(scale + " is scale");
    canvasRef.current.setAttrs({
      width: stageWidth * scale,
      height: stageHeight * scale,
      scaleX: scale,
      scaleY: scale,
    });
    canvasRef.current.draw();
  }

  function ChnageBG() {
    setCount((c) => c++);

    if (bgtype === "gradientflex") {
      setisLoading(true);

      nbgurl =
        size === "post"
          ? `https://source.unsplash.com/collection/55998366/1080x1080`
          : `https://source.unsplash.com/collection/55998366/1080x1920`;
      fetch(nbgurl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => res)
        .then((data) => {
          nbg.src = data.url;
        })
        .catch((err) => {
          alert("Error happened during fetching! Try Again");
          setisLoading(false);
        });
    }
    if (bgtype === "imageflex") {
      setisLoading(true);

      if (searchText !== "") {
        nbgurl =
          size === "post"
            ? `https://source.unsplash.com/featured/1080x1080/?${searchText}`
            : `https://source.unsplash.com/featured/1080x1920/?${searchText}`;
      } else {
        nbgurl =
          size === "post"
            ? `https://source.unsplash.com/random/1080x1080`
            : `https://source.unsplash.com/random/1080x1920`;
      }
      fetch(nbgurl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => res)
        .then((data) => {
          nbg.src = data.url;
        })
        .catch((err) => {
          alert("Error happened during fetching! Try Again");
          setisLoading(false);
        });
    }
  }

  function download() {
    ReactGA.event({
      category: "Downlaod",
      action: `downloaded ${
        showWatermark ? "with Watermark" : "without Watermark"
      }`,
    });
    ReactGA.event({
      category: "Downlaod ",
      action: `downloaded after ${count} Change`,
    });

    canvasRef.current.draw();
    var dataURL = canvasRef.current.toDataURL({
      pixelRatio: 1080 / canvasRef.current.width(),
    });
    downloadURI(dataURL, "flexeetclub.png");
  }

  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <>
      <div className="w-screen max-w-screen-2xl overflow-x-hidden">
        <div className=" grid grid-cols-4 gap-x-8 gap-y-8 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 mt-4 mb-28 mb md:mt-12 md:mb-40">
          <div
            className={classNames(
              "col-span-4 lg:col-span-6 grid grid-cols-6 gap-x-0 gap-y-0",
              { bgCyan: bgtype === "transparentflex" }
            )}
          >
            <div
              className={
                size === "post"
                  ? `aspect-w-1 aspect-h-1 col-span-6`
                  : `aspect-w-9 aspect-h-16 col-start-2 col-span-4`
              }
              id="canvasbox"
            >
              <Stage ref={canvasRef}>
                <Layer ref={layerRef}>
                  <Rect
                    height={stageHeight}
                    width={stageWidth}
                    ref={baseBGRef}
                    fill={colorN}
                    visible={bgtype === "colorflex" || bgtype === "borderflex"}
                  ></Rect>
                  <Rect
                    height={stageHeight}
                    width={stageWidth}
                    ref={baseBGRef}
                    fillPatternImage={bg}
                    visible={
                      bgtype === "imageflex" || bgtype === "gradientflex"
                    }
                  ></Rect>
                  <Group name="TweetGroup" ref={tweetGroupRef}>
                    {bgtype === "borderflex" ? (
                      <Rect
                        ref={tweetGroupBGRef}
                        width={840}
                        height={0}
                        cornerRadius={32}
                        stroke={getTextColor(mode, bgtype)}
                        strokeWidth={6}
                      ></Rect>
                    ) : (
                      <Rect
                        ref={tweetGroupBGRef}
                        width={840}
                        fill={mode === "light" ? "#FFFFFF" : "#000000"}
                        opacity={bgtype === "transparentflex" ? 0.6 : 0.8}
                        cornerRadius={32}
                      ></Rect>
                    )}
                    <Group name={"headerGroup"} ref={headerGroupRef}>
                      <Rect
                        name="headerBG"
                        fill="#FFFFFF"
                        opacity={0}
                        width={840}
                        height={160}
                      ></Rect>
                      <Circle
                        ref={ProfilePictureRef}
                        x={80}
                        y={80}
                        width={80}
                        height={80}
                        stroke={getTextColor(mode, bgtype)}
                        strokeWidth={bgtype === "borderflex" ? 6 : 0}
                      ></Circle>
                      <Text
                        ref={userNameRef}
                        name="profilename"
                        text={data["name"]}
                        x={140}
                        y={44}
                        align="left"
                        fontStyle="bold"
                        fontSize={24}
                        fontFamily={"Inter"}
                        lineHeight={1.5}
                        fill={getTextColor(mode, bgtype)}
                      />
                      <Text
                        name="username"
                        text={"@" + data["screen_name"]}
                        x={140}
                        y={80}
                        align="left"
                        fontStyle="normal"
                        fontSize={24}
                        fontFamily={"Inter"}
                        lineHeight={1.5}
                        fill={getTextColor(mode, bgtype)}
                      />
                      <Path
                        name="verified"
                        visible={data["verified"]}
                        ref={verifiedIconRef}
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        data="M6.57182 6.57171C5.24858 7.89496 4.71008 9.70555 4.95585 11.4255C3.56587 12.4678 2.6665 14.1289 2.6665 16C2.6665 17.871 3.56587 19.5321 4.95585 20.5745C4.71008 22.2944 5.24858 24.1047 6.57182 25.428C7.89483 26.7512 9.70543 27.2897 11.4253 27.044C12.4677 28.4339 14.1288 29.3333 15.9998 29.3333C17.8709 29.3333 19.532 28.4339 20.5743 27.044C22.2943 27.2897 24.1048 26.7512 25.4279 25.428C26.7511 24.1047 27.2896 22.2944 27.0438 20.5745C28.4338 19.5321 29.3332 17.871 29.3332 16C29.3332 14.1289 28.4338 12.4678 27.0438 11.4255C27.2896 9.70555 26.7511 7.89496 25.4279 6.57171C24.1048 5.2487 22.2943 4.7102 20.5743 4.95597C19.532 3.56599 17.8709 2.66663 15.9998 2.66663C14.1288 2.66663 12.4677 3.56599 11.4253 4.95597C9.70543 4.7102 7.89483 5.2487 6.57182 6.57171ZM21.3176 10.5554C21.9576 11.0167 22.1025 11.9096 21.6411 12.5496L15.9622 20.4278C15.2811 21.3726 13.9192 21.4904 13.086 20.6765L10.3039 17.9589C9.73948 17.4076 9.72887 16.5032 10.2802 15.9388C10.8315 15.3744 11.7359 15.3637 12.3003 15.9151L14.2912 17.8598L19.3234 10.8789C19.7847 10.2389 20.6776 10.094 21.3176 10.5554Z"
                        fill={getHashtagColor(mode, bgtype)}
                        y={45}
                      ></Path>

                      <Path
                        name="twitterLogo"
                        data="M64.2375 27.4482C64.275 27.9907 64.275 28.5332 64.275 29.0807C64.275 45.7631 51.575 65.0031 28.3525 65.0031V64.9931C21.4925 65.0031 14.775 63.0381 9 59.3331C9.9975 59.4531 11 59.5131 12.005 59.5156C17.69 59.5206 23.2125 57.6131 27.685 54.1006C22.2825 53.9981 17.545 50.4756 15.89 45.3331C17.7825 45.6981 19.7325 45.6231 21.59 45.1156C15.7 43.9256 11.4625 38.7507 11.4625 32.7407C11.4625 32.6857 11.4625 32.6332 11.4625 32.5807C13.2175 33.5582 15.1825 34.1007 17.1925 34.1607C11.645 30.4532 9.935 23.0732 13.285 17.3032C19.695 25.1907 29.1525 29.9857 39.305 30.4932C38.2875 26.1082 39.6775 21.5132 42.9575 18.4307C48.0425 13.6507 56.04 13.8957 60.82 18.9782C63.6475 18.4207 66.3575 17.3832 68.8375 15.9132C67.895 18.8357 65.9225 21.3182 63.2875 22.8957C65.79 22.6007 68.235 21.9307 70.5375 20.9082C68.8425 23.4482 66.7075 25.6607 64.2375 27.4482Z"
                        fill={getHashtagColor(mode, bgtype)}
                        x={720}
                        y={40}
                      ></Path>
                    </Group>
                    <KImage
                      ref={tweetTextCanvasRef}
                      x={40}
                      y={0}
                      image={canvas}
                      visible={canvas.height > 0}
                    ></KImage>
                    {isImage ? (
                      <RoundedImage
                        tweetMediaRef={tweetMediaRef}
                        makeCanvas={() => Update()}
                        cr={canvasRef}
                        x={40}
                        y={0}
                        height={
                          data["media"][0]["sizes"]["large"]["h"] *
                            (760 / data["media"][0]["sizes"]["large"]["w"]) >=
                          434
                            ? 434
                            : data["media"][0]["sizes"]["large"]["h"] *
                              (760 / data["media"][0]["sizes"]["large"]["w"])
                        }
                        width={760}
                        imageurl={
                          data["media"][0]["media_url_https"] +
                          "?format=jpg&name=large"
                        }
                        mode={mode}
                        bgtype={bgtype}
                      />
                    ) : null}
                  </Group>
                  <KImage
                    visible={showWatermark}
                    x={462}
                    y={size === "post" ? stageHeight - 64 : stageHeight - 210}
                    width={156}
                    height={40}
                    image={wmimg}
                  ></KImage>
                </Layer>
              </Stage>
            </div>
          </div>

          <div className="col-span-4 lg:col-start-8 lg:col-span-5">
            <div className="mobH4 md:deskH4 textGradient">
              It’s Ready! Go Flex It!!!
            </div>
            <div
              className="mobCTA-SMALL md:deskCTA-SMALL text-red-600 mt-4 hover:underline cursor-pointer"
              onClick={() => {
                window.location.reload();
              }}
            >
              {"Something Wrong? "}
              <span className="textGradient">Refresh the page</span>
            </div>

            <div className="mt-6"></div>
            {bgtype === "colorflex" || bgtype === "borderflex" ? (
              <>
                <div className="mobCTA-BIG md:deskCTA-BIG textBlack mt-8">
                  Change Color Hex:
                </div>

                <div className="py-4 px-8 gradientBorder border rounded-full mt-4">
                  <HexColorInput
                    color={colorN}
                    onChange={setNColor}
                    className="w-full bg-transparent focus:outline-none mobCTA-SMALL md:deskCTA-SMALL transform-none text-center"
                  />
                </div>
                <div className="content-center grid justify-center mt-4">
                  <HexColorPicker color={colorN} onChange={setNColor} />
                </div>
              </>
            ) : null}
            {bgtype === "imageflex" ? (
              <>
                <div className="mobCTA-BIG md:deskCTA-BIG textBlack mt-6">
                  Enter Keywords for Background:
                </div>
                <div className="py-4 px-8 gradientBorder border rounded-full mt-4">
                  <input
                    className="w-full bg-transparent focus:outline-none mobCTA-SMALL md:deskCTA-SMALL transform-none text-center"
                    value={searchText}
                    onChange={searchTextHandler}
                    placeholder="cars,flowers,mountanins"
                    type="text"
                    name=""
                    disabled={isLoading}
                  />
                </div>
              </>
            ) : null}
            <div className="mt-4"></div>
            {bgtype === "transparentflex" ||
            bgtype === "colorflex" ||
            bgtype === "borderflex" ? null : (
              <Button
                title="Change Background"
                buttonStyle="btnSecondary"
                buttonSize="btnBig"
                isDisabled={isLoading}
                fitType="btnFull"
                // onBtnClick={ChnageBG}
                isLoading={isLoading}
                onBtnClick={ChnageBG}
              />
            )}
            <div className="mt-8 flex flex-row justify-items-center content-center">
              <input
                type="checkbox"
                // name="show Watermark "
                // id="watermarktoggle"
                className="border-4 mr-2 my-auto"
                checked={!showWatermark}
                onClick={() => toggleWaterMark(!showWatermark)}
              />

              <div className="mobCTA-BIG md:deskCTA-BIG textBlack  ">
                Remove <span className="textGradient">FlexeetClub </span>
                Watermark
              </div>
            </div>

            <div className="mt-4">
              <Button
                title="Download Image"
                buttonStyle="btnPrimary"
                buttonSize="btnBig"
                isDisabled={isLoading}
                fitType="btnFull"
                onBtnClick={download}
              />
            </div>
            <div className="mobCTA-SMALL md:deskCTA-SMALL textBlack mt-8  mb-8">
              🦄 Don’t forget to mention
              <span className="textGradient">@flexeetclub </span> while you use
              it
            </div>
            <a
              href="https://tally.so/r/mDKMEn"
              target="_blank"
              rel="noreferrer"
            >
              <div className="mobCTA-BIG md:deskCTA-BIG textGradient text-center">
                Leave us a feedback!
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseTweet;
