import React from "react";
import Card from "../Card";
import { URLS , templates} from "../utils/templatesUtils";


const Alltemplates = () => {
  return (
    <>
      <div className="textBlack mobH4 lg:deskH4 mx-4 md:mx-12 lg:mx-32  mt-12 md:mt-16 lg:mt-20 gap-y-8  text-center lg:text-left">
        All Templates
      </div>
      <div className="max-w-screen-2xl grid grid-cols-4 md:grid-cols-8 gap-6 lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 items-center mt-8 md:mt-12 mb-28 md:mb-28 lg:mb-40">
        {templates.map((data) => (
          <Card name={data.name} url={data.url} imgURL={URLS[data.name]} />
        ))}
      </div>
    </>
  );
};

export default Alltemplates;
