import React, { useRef, useEffect, useState } from "react";
import { Stage, Layer, Image as KImage, Rect } from "react-konva";
import useImage from "use-image";
import { useWindowSize } from "@react-hook/window-size";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import Button from "../Button";
import Simplerect from "./baseBG";
import { HexColorPicker, HexColorInput } from "react-colorful";
import Modal from "react-overlays/Modal";
import CustomText from "../customText";
import html2canvas from "html2canvas";
import Konva from "konva";

const Playground = () => {
  const [isLoading, setisLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [color, setColor] = useColor("hex", "#6dc64c");
  const [colorN, setNColor] = useState("#aabbcc");
  const [show, setShow] = useState(false);
  const [canvasIMG, setCanvas] = useState();
  const [imgH, setIMGH] = useState(0);
  const [imgW, setIMGW] = useState(0);

  const canvasRef = useRef(null);
  const layerRef = useRef(null);
  const imgRef = useRef(null);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const [width, height] = useWindowSize();

  const [bgimage] = useImage("https://source.unsplash.com/random/1080x1080");

  let stageWidth = 1080;
  let stageHeight = 1080;

  let newbg = new Image();
  let newbgurl =
    searchText !== ""
      ? `https://source.unsplash.com/featured/1080x1080/?${searchText}`
      : "https://source.unsplash.com/random/1080x1080";
  newbg.crossOrigin = "Anonymous";
  newbg.onload = function () {
    console.log("NBG LOADED");
    imgRef.current.setAttrs({
      image: newbg,
    });
    canvasRef.current.draw();
    setisLoading(false);
  };

  let chnageBG = () => {
    setisLoading(true);
    console.log(searchText, "searching");
    fetch(newbgurl, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res)
      .then((data) => {
        console.log(data.url);
        newbg.src = data.url;
      })
      .catch((err) => {
        console.log("Error happened during fetching!", err);
        setisLoading(false);
      });
  };

  useEffect(() => {
    // ref.current.cache(); // not "cahce"
    // ref.current.filters([Konva.Filters.Blur]);
    // ref.current.blurRadius(100);
    // ref2.current.cache(); // not "cahce"
    // ref2.current.filters([Konva.Filters.Blur]);
    // ref2.current.blurRadius(20);
  }, []);

  useEffect(() => {
    reSize();
  }, [width, height]);

  // useEffect(() => {
  //   let container = document.getElementById("canvasbox");

  //   let containerWidth = container.offsetWidth;
  //   // to do this we need to scale the stage
  //   let scale = containerWidth / stageWidth;
  // }, []);

  const searchTextHandler = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setSearchText(e.target.value);
  };

  function reSize() {
    // now we need to fit stage into parent
    var container = document.getElementById("canvasbox");

    var containerWidth = container.offsetWidth;
    // to do this we need to scale the stage
    var scale = containerWidth / stageWidth;
    console.log(scale + " is scale");
    canvasRef.current.setAttrs({
      width: stageWidth * scale,
      height: stageHeight * scale,
      scaleX: scale,
      scaleY: scale,
    });
  }
  const sleep = async (ms) => {
    return await new Promise((resolve) => setTimeout(resolve, ms));
  };

  const convertToImg = () => {
    window.scrollTo(0, 0);
    var CustomTextElement = document.getElementById("textonly");
    html2canvas(CustomTextElement, {
      backgroundColor: null,
      scrollX: 0,
      scrollY: 0,
      useCORS: true,
      scale: 1,

      onclone: async (doc) => {
        await sleep(2000);
      },
    }).then((can) => {
      setCanvas(can);
      setIMGH(can.height);
      setIMGW(can.width);

      // setLoading(false);
    });
  };

  return (
    <div className="max-w-screen-2xl w-screen">
      <div className="grid  grid-cols-4 gap-x-8  lg:grid-cols-12 mx-4 md:mx-12 lg:mx-32 mt-4 mb-28 mb md:mt-12 md:mb-40">
        <div className=" col-span-4 lg:col-span-6 mt-4">
          <CustomText
            text={
              "“None of us know what will happen. Don’t spend time worrying about it. \n\nMake the most beautiful thing you can. \n\nTry to do that every day. \n\nThat’s it.”\n\n—Laurie Anderson"
            }
            funks={() => convertToImg()}
            isImage={false}
            mode="light"
          ></CustomText>
        </div>
        <div
          className=" col-span-4 lg:col-span-6  aspect-w-1 aspect-h-1"
          id="canvasbox"
        >
          <Stage ref={canvasRef} height={stageHeight} width={stageWidth}>
            <Layer ref={layerRef}>
              <Rect
                width={stageWidth}
                height={stageWidth}
                fillPatternImage={bgimage}
              ></Rect>
              {/* <Rect
                ref={ref}
                fill={"#FFFFFF"}
                opacity={0.64}
                width={stageWidth / 2}
                height={stageWidth / 2}
                x={500}
                y={0}
              ></Rect> */}
              <MyRect></MyRect>

              {/* <Rect
                ref={ref2}
                fill={"#2611B6"}
                opacity={0.64}
                width={stageWidth / 2}
                height={stageWidth / 2}
                x={stageWidth / 2}
                y={0}
              ></Rect>

              <Rect
                fill={"#FFFFFF"}
                opacity={0.7}
                width={stageWidth / 2}
                height={stageWidth / 2}
                x={0}
                y={stageHeight / 2}
              ></Rect> */}
              {/* <KImage
                ref={ref2}
                x={40}
                y={0}
                blurRadius={10}
                image={canvasIMG}
              ></KImage> */}
            </Layer>
          </Stage>
        </div>

        <div className="col-span-4 lg:col-span-6 mt-8">
          {/* <input
            className="border border-red-600 self-center w-full mb-4"
            value={searchText}
            onChange={searchTextHandler}
            placeholder="eg: cars,flowers,mountanins"
            type="text"
            name=""
            id=""
          /> */}

          {/* <Button
            title="Show Modal"
            buttonStyle="btnSecondary"
            buttonSize="btnBig"
            // isDisabled={isLoading}
            fitType="btnFull"
            // isLoading={isLoading}
            onBtnClick={() => setShow(true)}
          /> */}

          {/* <div className="mt-4"></div>
          {/* <ColorPicker
            width={300}
            height={200}
            color={color}
            onChange={setColor}
            hideHSB={true}
            hideRGB={true}
            dark={false}
          /> */}
          {/* <div className="content-center grid justify-center">
            <HexColorPicker color={colorN} onChange={setNColor} />
          </div> */}
          {/* 
          <div className="py-4 px-8 gradientBorder border rounded-full mt-4">
            <HexColorInput
              color={colorN}
              onChange={setNColor}
              className="w-full bg-transparent focus:outline-none mobCTA-SMALL md:deskCTA-SMALL transform-none text-center"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Playground;

class MyRect extends React.Component {
  componentDidMount() {
    this.rect.filters([Konva.Filters.Blur]);

    this.rect.blurRadius(90);
    this.rect.cache();
  }
  render() {
    return (
      <Rect
        // filters={[Konva.Filters.Blur]}
        // blurRadius={96}
        x={1080 / 2}
        y={1080 / 2}
        width={800}
        offsetX={400}
        offsetY={250}
        height={500}
        stroke={true}
        strokeWidth={2}
        fill="white"
        opacity={0.8}
        ref={(node) => {
          this.rect = node;
        }}
      />
    );
  }
}
