import * as React from "react";
// import "../styles/global.css"
import logo from "../images/FlexeetLogo.svg";
import igGD from "../images/Icons/Instagram_gd.svg";
import twitterGD from "../images/Icons/Twitter_gd.svg";
import mailGD from "../images/Icons/Mail_gd.svg";
import MenuGD from "../images/Icons/Menu_gd.svg";
import Button from "./Button";
import IconLink from "./IconLink";
import { isIOS } from "react-device-detect";

const Header = () => {
  return (
    <header className="justify-center content-center my-0">
     <div
          div
          className="mobCTA-SMALL md:deskCTA-SMALL text-center textCyan px-4 py-2 bgGradient"
        >
          Flexeetclub is offline due to changes in Twitter's API. We're working on a fix. Thanks for your patience!
        </div>
      <div className="mx-4 md:mx-12 lg:mx-32 max-w-screen-2xl self-center flex flex-row justify-between items-center my-5 ">
        <a href="/">
          <img src={logo} alt="flexeet logo" className="my-auto" />
        </a>
        <ul className="lg:flex hidden flex-row gap-8 my-auto mx-0 justify-items-center items-center">
          <IconLink
            title="Instagram"
            url="https://www.instagram.com/flexeetclub/"
            iconSVG={igGD}
            type="header"
          />
          <IconLink
            title="Twitter"
            url="https://www.twitter.com/flexeetclub/"
            iconSVG={twitterGD}
            type="header"
          />
          <IconLink
            title="Contact"
            url="mailto:hello@flexeet.club"
            iconSVG={mailGD}
            type="header"
          />
          <a href="/">
            <Button
              title="Start Creating Now"
              buttonStyle="btnPrimary"
              buttonSize="btnSmall"
              isDisabled={false}
            ></Button>
          </a>
        </ul>

        <img
          src={MenuGD}
          alt="menu"
          onClick={() => {
            console.log("open");
            document.getElementById("overlay").style.top = "0";
            document.getElementById("overlay").style.left = "0";
          }}
          className="lg:hidden md:flex sm:flex flex-row mx-2 my-auto justify-items-center items-center h-8 w-8"
        />
      </div>
    </header>
  );
};

export default Header;
